import { defineStore } from 'pinia';
import { useClientsStore } from '@/stores/clients';
import { grpc } from '@improbable-eng/grpc-web';
import {
	onMessageResponseAsync,
	onEndResponseAsync,
} from '@/functions/handleGrpcResponse';

import { Users } from 'lex-proto/users_pb_service';
import {
	User,
	UserListing,
	StoreInvite,
	Group,
	PermissionNamespace,
	ChartSettings,
} from 'lex-proto/users_pb';
import isEmpty from 'lodash/isEmpty';

import { Empty } from 'google-protobuf/google/protobuf/wrappers_pb';

function buildUserRequest(userData) {
	const userRequest = new User();

	if (!isEmpty(userData.id)) userRequest.setId(userData.id);
	userRequest.setState(userData.state);
	userRequest.setEmail(userData.email);
	userRequest.setFirstName(userData.firstName);
	userRequest.setLastName(userData.lastName);

	return userRequest;
}
// function buildChartSettingsRequest() {
// 	console.log('buildChartSettingsRequest from account.js');
// }
function buildAdminGroupRequest(groupData) {
	const groupRequest = new Group();

	if (!isEmpty(groupData.id)) groupRequest.setId(groupData.id);
	groupRequest.setName(groupData.name);

	let user = new User();
	user.setId(JSON.parse(localStorage.getItem('userSession')).identity.id);
	groupRequest.addUser(user);

	// Checked if groupData.permissions is an array to change user groupe else for create user group
	if (Array.isArray(groupData.pluginsList)) {
		groupRequest.setPluginsList([...groupData.pluginsList]);
	} else {
		groupRequest.setPluginsList([groupData.pluginsList]);
	}

	if (groupData.permissions) {
		let namespacesObj = {};
		groupData.permissions.forEach((permission) => {
			let paddedPermission = String(permission).padStart(4, 0);
			if (namespacesObj[paddedPermission.slice(0, 2)]) {
				namespacesObj[paddedPermission.slice(0, 2)].push(permission);
			} else {
				namespacesObj[paddedPermission.slice(0, 2)] = [permission];
			}
		});
		Object.entries(namespacesObj).forEach(
			([namespaceCode, permissions]) => {
				let namespaceReq = new PermissionNamespace();
				namespaceReq.setNamespace(parseInt(namespaceCode));
				namespaceReq.setPermissionsList(permissions);
				groupRequest.addPermissionNamespaces(namespaceReq);
			}
		);
	}

	return groupRequest;
}

export const useAccountsStore = defineStore('accounts', {
	state: () => ({}),
	actions: {
		getAllResources() {
			return new Promise((resolve, reject) => {
				const usersRequest = new UserListing();

				grpc.invoke(Users.ListUsers, {
					request: usersRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		// createUser( userData) {
		// 	return new Promise((resolve, reject) => {
		// 		const userRequest = buildUserRequest(userData);

		// 		grpc.invoke(Users.CreateUser, {
		// 			request: userRequest,
		// 			host: import.meta.env.VITE_API_URL,
		// 			metadata: {
		// 				'x-client-id': useClientsStore().getSelectedStore,
		// 			},
		// 			onMessage: (message) =>
		// 				onMessageResponseAsync(message, resolve, reject),
		// 			onEnd: (code, msg, trailers) =>
		// 				onEndResponseAsync(
		// 					code,
		// 					msg,
		// 					trailers,
		// 					resolve,
		// 					reject
		// 				),
		// 		});
		// 	});
		// },

		updateUser(userData) {
			return new Promise((resolve, reject) => {
				const userRequest = buildUserRequest(userData);

				grpc.invoke(Users.UpdateUser, {
					request: userRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		// Sending the client ID in metadata for SetClientCharts RPC
		setClientCharts(chartSettings) {
			return new Promise((resolve, reject) => {
				// Create a new instance of ChartSettings
				const chartSettingsRequest = new ChartSettings();
				// console.log(chartSettings);

				// Populate enabled_charts (assuming ChartType enums are valid)
				chartSettingsRequest.setEnabledChartsList(chartSettings);

				// Set updated_at
				// chartSettingsRequest.setUpdatedAt(chartSettings.updated_at);
				// console.log('chartSettingsRequest', chartSettingsRequest);

				// Make the gRPC request
				grpc.invoke(Users.SetClientCharts, {
					request: chartSettingsRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore, // Add the client ID here
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		// Sending the client ID in metadata for GetClientCharts RPC
		getClientCharts(user) {
			return new Promise((resolve, reject) => {
				// const userRequest = buildUserRequest(user);
				// console.log('userRequest', userRequest);
				const userRequest = new User();
				// console.log(user);

				userRequest.setId(user.id);
				grpc.invoke(Users.GetClientCharts, {
					request: userRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore, // Add the client ID here
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		// Sending the client ID in metadata for GenerateChartToken RPC
		GenerateChartToken() {
			return new Promise((resolve, reject) => {
				const userRequest = new User();
				// userRequest.setId(user.id);

				grpc.invoke(Users.GenerateChartToken, {
					request: userRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore, // Add the client ID here
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		// deleteUser( { id }) {
		// 	return new Promise((resolve, reject) => {
		// 		const userRequest = new User();
		// 		userRequest.setId(id);

		// 		grpc.invoke(Users.DeleteUser, {
		// 			request: userRequest,
		// 			host: import.meta.env.VITE_API_URL,
		// 			metadata: {
		// 				'x-client-id': useClientsStore().getSelectedStore,
		// 			},
		// 			onMessage: (message) =>
		// 				onMessageResponseAsync(message, resolve, reject),
		// 			onEnd: (code, msg, trailers) =>
		// 				onEndResponseAsync(
		// 					code,
		// 					msg,
		// 					trailers,
		// 					resolve,
		// 					reject
		// 				),
		// 		});
		// 	});
		// },

		getUserById(userId) {
			return new Promise((resolve, reject) => {
				const userRequest = new User();
				userRequest.setId(userId);

				grpc.invoke(Users.GetUserById, {
					request: userRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		/**
		 * Get details of a single group by ID
		 */
		getAdminGroup(groupId) {
			return new Promise((resolve, reject) => {
				const groupRequest = new Group();
				groupRequest.setId(groupId);

				grpc.invoke(Users.GetAdminGroup, {
					request: groupRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		/**
		 * Get all admin groups of the current selected store
		 */
		getAdminGroups() {
			return new Promise((resolve, reject) => {
				grpc.invoke(Users.GetAdminGroups, {
					request: new Empty(),
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		/**
		 * Get groups which parameter admin is part of
		 */
		getUserGroups(adminId) {
			return new Promise((resolve, reject) => {
				const userRequest = new User();
				userRequest.setId(adminId);
				grpc.invoke(Users.GetUserGroups, {
					request: userRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		/**
		 * Get all stores (client_ids) that the user has access to
		 */
		getUserStores(adminId) {
			return new Promise((resolve, reject) => {
				const userRequest = new User();
				userRequest.setId(adminId);
				grpc.invoke(Users.GetUserStores, {
					request: userRequest,
					host: import.meta.env.VITE_API_URL,
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		linkUserToStore([{ id, email }, storeId]) {
			return new Promise((resolve, reject) => {
				const userRequest = new User();
				userRequest.setId(id);
				userRequest.setEmail(email);
				grpc.invoke(Users.LinkUserToStore, {
					request: userRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': storeId,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		inviteUser({ email }, groupId) {
			return new Promise((resolve, reject) => {
				const inviteRequest = new StoreInvite();
				inviteRequest.setEmail(email);
				inviteRequest.setGroupId(groupId);
				grpc.invoke(Users.InviteUser, {
					request: inviteRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		acceptInvite(user) {
			return new Promise((resolve, reject) => {
				const userRequest = new User();
				userRequest.setId(user.id);
				userRequest.setEmail(user.email);
				userRequest.setToken(user.token);
				grpc.invoke(Users.AcceptInvite, {
					request: userRequest,
					host: import.meta.env.VITE_API_URL,
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		getInvites() {
			return new Promise((resolve, reject) => {
				grpc.invoke(Users.GetInvites, {
					request: new Empty(),
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		deleteInvite(email) {
			return new Promise((resolve, reject) => {
				const userRequest = new User();
				userRequest.setEmail(email);

				grpc.invoke(Users.DeleteInvite, {
					request: userRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		removeUserFromStore({ id }) {
			return new Promise((resolve, reject) => {
				const userRequest = new User();
				userRequest.setId(id);

				grpc.invoke(Users.RemoveUserFromStore, {
					request: userRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		addUserToGroup(groupData) {
			return new Promise((resolve, reject) => {
				const groupRequest = buildAdminGroupRequest(groupData);

				groupRequest.clearUserList();
				let user = new User();
				user.setId(groupData.userList[0].id);
				groupRequest.addUser(user);

				grpc.invoke(Users.AddUserToGroup, {
					request: groupRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		removeUserFromGroup(groupData) {
			return new Promise((resolve, reject) => {
				const groupRequest = buildAdminGroupRequest(groupData);

				groupRequest.clearUserList();
				let user = new User();
				user.setId(groupData.userList[0].id);
				groupRequest.addUser(user);

				grpc.invoke(Users.RemoveUserFromGroup, {
					request: groupRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
						// internal_client_id: useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		createAdminGroup(groupData) {
			return new Promise((resolve, reject) => {
				const groupRequest = buildAdminGroupRequest(groupData);

				grpc.invoke(Users.CreateAdminGroup, {
					request: groupRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		updateAdminGroup(groupData) {
			return new Promise((resolve, reject) => {
				const groupRequest = buildAdminGroupRequest(groupData);

				grpc.invoke(Users.UpdateAdminGroup, {
					request: groupRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		deleteAdminGroup({ id }) {
			return new Promise((resolve, reject) => {
				const groupRequest = new Group();
				groupRequest.setId(id);

				grpc.invoke(Users.DeleteAdminGroup, {
					request: groupRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
	},
	getters: {
		getGroupSetter() {
			//"permissions: []" used to on screen to prepare selected permissions
			return Object.assign({ permissions: [] }, new Group().toObject());
		},
		getUserSetter() {
			return new User().toObject();
		},
	},
});
