export class StreamUploadHelper {
	static defaultChunkSize = 25 * 1024; //bytes
	#busy;
	#stop;
	#readingSize;
	#offset;
	#file;

	constructor(
		onChunkRead,
		onDoneReading,
		onFailReading,
		chunkSize = StreamUploadHelper.defaultChunkSize
	) {
		this.#stop = false;
		this.uploadChunkSize = chunkSize;
		this.onChunkRead = onChunkRead || function () {};
		this.onDoneReading = onDoneReading || function () {};
		this.onFailReading = onFailReading || function () {};
	}

	breakExecutionOnNextChunk() {
		this.#stop = true;
	}

	#chunkReaderBlock() {
		if (this.#stop) {
			this.#stop = false; //reset value
			return;
		}
		let reader = new FileReader();
		reader.onload = (evt) => {
			if (evt.target.error == null) {
				this.#offset += this.uploadChunkSize;
				//skip file type header (37 chars)
				this.onChunkRead(evt.target.result.substring(37)); // callback for handling read chunk
			} else {
				this.onFailReading(evt.target.error);
				this.#busy = false;
				return;
			}
			if (this.#offset >= this.#readingSize) {
				this.onDoneReading();
				this.#busy = false;
				return;
			}
			this.#chunkReaderBlock();
		};

		reader.readAsDataURL(
			this.#file.slice(this.#offset, this.uploadChunkSize + this.#offset)
		);
	}

	parseFileInChunks(file) {
		if (this.#busy) {
			this.onFailReading(
				'Object instance can only process one file at a time. Please use another instance or wait the process finish'
			);
			return;
		}
		this.#offset = 0;
		this.#readingSize = file.size;
		this.#file = file;

		this.#chunkReaderBlock();
	}

	parseStringInChunks(stringContent) {
		let offset = 0;
		while (offset < stringContent.length && !this.stop) {
			let chunkContent = '';
			try {
				// Convert the string to a Base64-safe format
				chunkContent = btoa(
					new TextEncoder()
						.encode(
							stringContent.slice(
								offset,
								offset + this.uploadChunkSize
							)
						)
						.reduce(
							(data, byte) => data + String.fromCharCode(byte),
							''
						)
				);
			} catch (e) {
				console.log(e);
				return;
			}
			offset += this.uploadChunkSize;
			this.onChunkRead(chunkContent);
		}
		if (this.stop) {
			this.stop = false;
			return;
		} else {
			this.onDoneReading();
		}
	}

	parseContentInChunks(content) {
		if (content instanceof File) {
			this.parseFileInChunks(content);
		} else {
			this.parseStringInChunks(content);
		}
	}
}
