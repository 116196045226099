import { helpers } from '@vuelidate/validators';
import { Timezone } from 'lex-proto/settings_pb';
import { Currency } from 'lex-proto/money_pb';
import { RuleSortBy } from 'lex-proto/product_pb';
import { keyToTimezone } from '@/functions/timezones';

import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import transform from 'lodash/transform';

import { faker } from '@faker-js/faker/locale/en_US';
import { addresses } from '@/utils/addresses-us-500.min.json';
import md5 from 'md5';

export const getFromBetween = (text, sub1, sub2) => {
	if (text.indexOf(sub1) < 0 || text.indexOf(sub2) < 0) return false;
	var SP = text.indexOf(sub1) + sub1.length;
	var string1 = text.substr(0, SP);
	var string2 = text.substr(SP);
	var TP = string1.length + string2.indexOf(sub2);
	return text.substring(SP, TP);
};

export const escapeCommas = (text) => {
	if (text) {
		// Replace tab characters with a visible representation (e.g., [TAB])
		text = text.replace(/\t/g, ' ');

		// Escape backslashes by replacing each \ with \\
		text = text.replace(/\\/g, '\\\\');

		// Escape double quotes
		text = text.replace(/"/g, '\\"');

		// Escape commas if needed (example: using a different placeholder)
		// text = text.replace(/,/g, '[COMMA]');
	}
	return text;
};

export const truncateString = (str, num) => {
	if (str.length <= num) {
		return str;
	}
	return str.slice(0, num) + '...';
};

export const capitalize = (value) => {
	if (!value) return '';
	value = value.toString();
	return value.charAt(0).toUpperCase() + value.slice(1);
};

export const capitalizeAll = (value) => {
	if (!value) return '';
	value = value.toString().toLowerCase();
	return value.replace(/\w\S*/g, (w) =>
		w.replace(/^\w/, (c) => c.toUpperCase())
	);
};

// eslint-disable-next-line no-unused-vars
export const getJsonObjectFromComment = (str) => {
	if (!str) {
		return;
	}

	const match = str.match(/^(.*?)\s*#\s*<start-rule-query>/);
	const sqlText = match ? match[1].trim() : '';

	const start = '<start-rule-query>';
	const end = '<end-rule-query>';
	let extractedString = getFromBetween(str, start, end); //  ( product.name = "hell#o") # <start-rule-query> product.name = "hello#testing" <end-start-rule-query> #
	if (extractedString) {
		let obj = JSON.parse(extractedString);
		obj.sqlText = sqlText;
		return obj;
	}
};

export const makeJsonStringPlusSqlStringAction = (sqlText, obj) => {
	// Operator map for converting codes to names
	const operatorNameMap = {
		7: 'contains',
		8: 'not contains',
		1: '=',
		3: '>',
		4: '>=',
		5: '<',
		6: '<=',
	};

	// Helper function to process nested conditions arrays
	const processConditionsArr = (conditionsArr, parentOperators = []) => {
		// Filter out empty arrays first
		const filteredArr = conditionsArr.filter(
			(group) => Array.isArray(group) && group.length > 0
		);

		if (filteredArr.length === 0) return '';

		let sqlConditions = [];

		for (let i = 0; i < filteredArr.length; i++) {
			const group = filteredArr[i];

			if (Array.isArray(group)) {
				if (group.length > 1) {
					// Get the operator from the second element for joining conditions
					const operator = group[1]?.operatorRow || '&&';
					// Wrap in parentheses if group has multiple elements
					const processedGroup = processGroup(group, operator);
					sqlConditions.push(`(${processedGroup})`);
				} else {
					// Single element doesn't need parentheses
					sqlConditions.push(processGroup(group));
				}

				if (i > 0) {
					parentOperators.push('&&'); // Default to && for between groups
				}
			}
		}

		// Join conditions with operators
		let result = sqlConditions[0] || '';
		for (let i = 1; i < sqlConditions.length; i++) {
			const operator = parentOperators[i - 1] || '&&';
			result = `${result} ${operator} ${sqlConditions[i]}`;
		}

		return result;
	};

	// Helper function to process a single group
	const processGroup = (group, groupOperator = '&&') => {
		return group
			.map(({ conditions, fieldName }) => {
				const formattedConditions = conditions
					.map(({ operatorCondition, valueCondition }) => {
						const operatorName =
							operatorNameMap[operatorCondition] ||
							operatorCondition;

						// Handle array-like valueCondition
						let parsedValueCondition = valueCondition;

						if (
							typeof valueCondition === 'string' &&
							valueCondition.startsWith('[') &&
							valueCondition.endsWith(']')
						) {
							try {
								parsedValueCondition =
									JSON.parse(valueCondition);
							} catch (e) {
								parsedValueCondition = valueCondition
									.slice(1, -1)
									.split(',')
									.map((item) =>
										item
											.trim()
											.replace(/^\[|\]$/g, '')
											.replace(/^"|"$/g, '')
									);
							}
						}

						// Format the value condition
						let formattedValueCondition;
						if (Array.isArray(parsedValueCondition)) {
							const quotedValues = parsedValueCondition
								.map((v) => {
									const trimmed = v.trim();
									return trimmed.startsWith('"') &&
										trimmed.endsWith('"')
										? trimmed
										: `"${trimmed}"`;
								})
								.join(', ');
							formattedValueCondition = `[ ${quotedValues} ]`;
						} else {
							const trimmed = parsedValueCondition?.trim() || '';
							formattedValueCondition =
								trimmed.startsWith('"') && trimmed.endsWith('"')
									? trimmed
									: `"${trimmed}"`;
						}

						return `${fieldName} ${operatorName} ${formattedValueCondition}`;
					})
					.join(' && ');

				return formattedConditions;
			})
			.join(` ${groupOperator} `);
	};

	// Ensure conditionsArr is processed correctly, considering nested structure
	if (obj && Array.isArray(obj.conditionsArr)) {
		let parentOperators = [];

		// Iterate over the top-level conditionsArr and track the operator for each group
		for (let i = 0; i < obj.conditionsArr.length; i++) {
			const group = obj.conditionsArr[i];

			// We only want the operator of the first condition in each group
			if (i > 0) {
				const operatorRow = group[0]?.operatorRow;
				if (operatorRow) {
					// Only add the operator for separating condition groups
					parentOperators.push(operatorRow);
				}
			}
		}

		// Flatten the conditions array if necessary
		const flattenedConditionsArr = obj.conditionsArr;

		// Process the conditions and generate SQL text
		const conditionsSqlText = processConditionsArr(
			flattenedConditionsArr,
			parentOperators
		);
		sqlText = conditionsSqlText; // Use constructed SQL string from conditions
	}

	// Return the formatted SQL string with JSON object
	return `${sqlText} # <start-rule-query> ${JSON.stringify(
		obj
	)} <end-rule-query> #`;
};
export const makeJsonStringPlusSqlString = (sqlText, obj) => {
	return `${sqlText} # <start-rule-query> ${JSON.stringify(
		obj
	)} <end-rule-query> #`;
};

export const fieldSetter = (fieldName) => {
	return `set${capitalize(fieldName)}`;
};

export const resourceListingSet = (
	listingRequest,
	page,
	ruleId,
	query,
	sortByList,
	perPage
) => {
	if (isUndefined(resourceListingSet)) return;

	if (ruleId && !isUndefined(listingRequest.setRuleId))
		listingRequest.setRuleId(ruleId);
	if (page) listingRequest.setPit(page);
	if (perPage) listingRequest.setDisplayResult(perPage);

	if (!isEmpty(query))
		listingRequest.setRuleQuery(
			Array.isArray(query) ? query.join(' ') : query
		);

	if (!isEmpty(sortByList)) {
		if (!Array.isArray(sortByList)) sortByList = [sortByList];
		sortByList.forEach(([fieldName, sortOrder]) => {
			let sort = new RuleSortBy();
			sort.setColumnName(fieldName);
			sort.setSortOrder(sortOrder);
			listingRequest.addSortBy(sort);
		});
	}

	return listingRequest;
};

// validation
// export const isDate = () =>

export const requiredIfAndIgnoreDefault = (
	param,
	forcedValue,
	forcedValueText
) =>
	helpers.withParams(
		{
			type: 'contains',
			value: param,
			forceValue: { value: forcedValue, text: forcedValueText },
		},
		(value, vm) => {
			return vm[param] || (!vm[param] && value != '');
		}
	);

export const minDate = (param) =>
	// minValue(Math.floor(Date.now() / 1000))
	helpers.withParams({ type: 'minValue', value: param }, (value) => {
		if (typeof value === 'object') value = value.value;

		return (
			value >= Math.floor(new Date().setHours(0, 0, 0) / 1000) ||
			value == '' ||
			typeof value == 'undefined'
		);
	});

export const maxValueBasedOnOtherValue = (param) =>
	helpers.withParams(
		{ type: 'maxValueBasedOnOtherValue', value: param },
		(value, vm) => {
			value = parseFloat(value);
			return value <= vm[param] || vm[param] == 0; // if other value 0 = infinite
		}
	);

export const minValueBasedOnOtherValue = (param) =>
	helpers.withParams(
		{ type: 'minValueBasedOnOtherValue', value: param },
		(value, vm) => {
			value = parseFloat(value);
			return value >= vm[param] || value == 0;
		}
	);

// @todo need to add rule if it's not defined, ok or blocking. ?
// export const phoneFormatCountry = paramPhoneNumberValid =>
// 	helpers.withParams(
// 		{ type: 'phoneFormatCountry', value: paramPhoneNumberValid },
// 		(value, vm) => {
// 			return (
// 				vm[paramPhoneNumberValid] ||
// 				value == '' ||
// 				typeof value == 'undefined'
// 			);
// 		}
// 	);

export const provinceMandatory = (InfoList, countryCode, conditionField) =>
	helpers.withParams({ requiredCondition: conditionField }, (value, vm) => {
		if (vm.addressCountryCode == '') {
			return true; // if country not set, don't apply the rule
		} else if (typeof vm[InfoList] != 'undefined') {
			return (
				vm[InfoList][vm.addressCountryCode].zonesList.length == 0 ||
				(vm[InfoList][vm.addressCountryCode].zonesList.length > 0 &&
					value != '')
			);
		} else {
			return true;
		}
	});

export const phoneValidation = helpers.regex(/^[+0-9]*$/);

export const urlValidation = helpers.regex(/^[\w\d-]*$/);

export const compareObject = (object, base) => {
	function changes(object, base) {
		return transform(object, (result, value, key) => {
			if (!isEqual(value, base[key])) {
				result[key] =
					isObject(value) && isObject(base[key])
						? changes(value, base[key])
						: value;
			}
		});
	}
	return changes(object, base);
};

export const isAFileObject = (data) => {
	return typeof data == 'object' && data instanceof File;
};

export const SETTINGS_KEY = 'settings';

export const getStoreSettings = () => {
	return parseStorageJson(SETTINGS_KEY);
};

export const getStoreCurrency = () => {
	return getStoreSettings() !== null
		? Object.keys(Currency)[getStoreSettings().storeCurrency.currency]
		: 'USD';
};

export const getStoreCountry = () => {
	return getStoreSettings() !== null &&
		typeof getStoreSettings().storeAddress != 'undefined'
		? getStoreSettings().storeAddress.country.value
		: null;
};

export const getStoreCountryCode = () => {
	return getStoreSettings() !== null &&
		typeof getStoreSettings().storeAddress != 'undefined'
		? getStoreSettings().storeAddress.countryCode.value
		: null;
};

export const getStoreTimezone = () => {
	if (
		getStoreSettings() !== null &&
		typeof getStoreSettings().storeStandardFormats != 'undefined'
	) {
		return keyToTimezone(
			getEnumKeyFromValue(
				Timezone,
				getStoreSettings().storeStandardFormats.timezone
			)
		);
	}

	return null;
};

export const simplePluralize = (count, text) => {
	if (count > 1) return text + 's';
	return text;
};

export const parseStorageJson = (storageKey) => {
	return parseJson(localStorage.getItem(storageKey));
};

export const parseJson = (value) => {
	try {
		return JSON.parse(value);
	} catch {
		return null;
	}
};

export const getEnumKeyFromValue = (enumObject, value) => {
	let foundEntry = Object.entries(enumObject).find(
		(entry) => entry[1] == value
	);
	if (typeof foundEntry === 'undefined') return value;
	return foundEntry[0];
};

export const eitherNotEmpty = (optionA, optionB, defaultValue = '') => {
	if (!isEmpty(optionA)) {
		if (!isUndefined(optionA.value) && !isEmpty(optionA.value.trim()))
			return optionA.value;

		if (isUndefined(optionA.value) && !isEmpty(optionA.trim()))
			return optionA;
	}

	if (!isEmpty(optionB)) {
		if (!isUndefined(optionB.value) && !isEmpty(optionB.value.trim()))
			return optionB.value;

		if (isUndefined(optionB.value) && !isEmpty(optionB.trim()))
			return optionB;
	}

	return defaultValue;
};

export const urlElementFromUrl = (url) => {
	let parser = document.createElement('a');
	parser.href = url;
	return parser;
};

export const getMainDomainNameFromUrl = (url) => {
	if (url.slice(0, 4) !== 'http') return url.split('.').shift();
	return urlElementFromUrl(url).hostname.split('.').shift();
};

export const getCookie = (cookieName) => {
	let name = cookieName + '=';
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return null;
};

export const setCookie = (cookieName, cookieValue = '', options = {}) => {
	let cookie = `${cookieName}=${cookieValue};`;
	cookie += `domain=${
		options.domain ? options.domain : import.meta.env.VITE_COOKIES_DOMAIN
	};`;
	cookie += `path=${!isUndefined(options.path) ? options.path : '/'};`;
	cookie += `samesite=${options.samesite ? options.samesite : 'Lax'};`;
	if (!isUndefined(options.expires)) cookie += `expires=${options.expires};`;

	document.cookie = cookie;
};

export const generateRandomAddress = () => {
	let randomAddress = addresses[Math.floor(Math.random() * addresses.length)];
	return {
		firstName: faker.name.firstName(),
		lastName: faker.name.lastName(),
		street1: randomAddress.address1,
		street2: randomAddress.address2,
		city: randomAddress.city,
		province: randomAddress.state,
		provinceCode: randomAddress.state,
		country: 'United States',
		countryCode: 'US',
		zip: randomAddress.postalCode,
	};
};

export function splitArrayIntoChunks(array, chunkSize) {
	const result = [];
	for (let i = 0; i < array.length; i += chunkSize) {
		const chunk = array.slice(i, i + chunkSize);
		result.push(chunk);
	}

	return result;
}

export function randomName(length = 10) {
	const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
	let result = '';
	for (let i = 0; i < length; i++) {
		result += letters.charAt(Math.floor(Math.random() * letters.length));
	}
	return result;
}

// Logic Function Find Differences in two arrays
function getVariantKey(variant) {
	return variant.variantId || JSON.stringify(variant);
}

// Implement comparison for the relevant fields
// A helper method to compare if two items are equal (deep comparison)
function areItemsEqual(item1, item2) {
	// This function should be implemented to compare all relevant properties of the items
	// For simplicity, you can use JSON.stringify, but consider a deep comparison library for complex objects
	return JSON.stringify(item1) === JSON.stringify(item2);
}
export function findDifferences(newList, oldList) {
	const getKey = getVariantKey; // Shorten for convenience

	// Create a map for quick lookup of old items
	const oldMap = new Map(oldList.map((item) => [getKey(item), item]));

	const added = [];
	const removed = [];
	const updated = [];

	const newSet = new Set(newList.map((item) => getKey(item)));

	// Determine added and updated items
	for (const newItem of newList) {
		const key = getKey(newItem);
		if (!oldMap.has(key)) {
			added.push(newItem);
		} else {
			const oldItem = oldMap.get(key);
			if (!areItemsEqual(newItem, oldItem)) {
				updated.push(newItem);
			}
			oldMap.delete(key);
		}
	}

	for (const [key, oldItem] of oldMap) {
		if (!newSet.has(key)) {
			removed.push(oldItem);
		}
	}

	return { added, removed, updated };
}

export function generateRandomNumbers(count) {
	let randomNumbers = [];

	for (let i = 0; i < count; i++) {
		let randomNumber = Math.floor(Math.random() * 10); // Random number between 0 and 9
		randomNumbers.push(randomNumber);
	}

	return randomNumbers.join(''); // Join numbers as a string
}

export function getLastRoute(route) {
	// Split the route string by '/' and return the last part
	return route.split('/').pop();
}

export function base64ToUtf8(base64String) {
	try {
		// Log the input string for debugging

		// Ensure the Base64 string is properly padded
		if (!/^[A-Za-z0-9+/]*={0,2}$/.test(base64String)) {
			throw new Error('Invalid Base64 string format');
		}

		// Decode the Base64 string into a byte array
		const byteArray = Uint8Array.from(atob(base64String), (char) =>
			char.charCodeAt(0)
		);

		// Convert the byte array to a UTF-8 string
		const utf8String = new TextDecoder().decode(byteArray);
		return utf8String;
	} catch (error) {
		console.error('Error decoding Base64 string:', error.message);
		throw error;
	}
}

export const generateFileHash = (content) => {
	return md5(content).toString();
};
