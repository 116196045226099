import { defineStore } from 'pinia';
import { useClientsStore } from '@/stores/clients';
import { grpc } from '@improbable-eng/grpc-web';
import {
	onMessageResponseAsync,
	onEndResponseAsync,
} from '@/functions/handleGrpcResponse';
import { Settings } from 'lex-proto/settings_pb_service';
import {
	SettingsRequest,
	Legal,
	General,
	Checkout,
	StoreDetails,
	StandardFormats,
	StoreCurrency,
	FormOptions,
	OrderProcessing,
	AbandonedCheckout,
	Analytics,
	Google,
	RedirectListing,
	RedirectRequest,
	BlockedIPListing,
	BlockedIPRequest,
} from 'lex-proto/settings_pb';
import { Address } from 'lex-proto/address_pb';

import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';

import isEmpty from 'lodash/isEmpty';

export const useSettingsStore = defineStore('settings', {
	state: () => ({}),
	actions: {
		getGeneralSettings() {
			return new Promise((resolve, reject) => {
				const emptyRequest = new Empty();

				grpc.invoke(Settings.GetStoreSettings, {
					request: emptyRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		saveGeneralSettingsChanges(generalSettingsChanged) {
			return new Promise((resolve, reject) => {
				const settingsRequest = new SettingsRequest();
				const generalSettings = new General();

				//store details
				const storeDetails = new StoreDetails();

				let storeNameValue = new StringValue();
				storeNameValue.setValue(
					generalSettingsChanged.storeDetails.storeName
				);
				storeDetails.setStoreName(storeNameValue);

				let storeEmailValue = new StringValue();
				storeEmailValue.setValue(
					generalSettingsChanged.storeDetails.storeEmail
				);
				storeDetails.setStoreEmail(storeEmailValue);
				storeDetails.setStoreIndustry(
					generalSettingsChanged.storeDetails.storeIndustry
				);
				storeDetails.setStoreLogo(
					generalSettingsChanged.storeDetails.storeLogo
				);
				generalSettings.setStoreDetails(storeDetails);

				//store address
				const storeAddress = new Address();

				storeAddress.setAddressType(Address.ADDRESS_TYPE.STORE);

				let fieldsList = [
					'street1',
					'street2',
					'city',
					'country',
					'province',
					'zip',
					'phone',
					'fullName',
					'company',
					'email',
					'countryCode',
					'provinceCode',
					'firstName',
					'lastName',
				];
				Object.keys(generalSettingsChanged.storeAddress).forEach(
					(field) => {
						if (fieldsList.includes(field)) {
							let protoValue = new StringValue();
							protoValue.setValue(
								generalSettingsChanged.storeAddress[field]
							);
							let fnName =
								'set' +
								field.charAt(0).toUpperCase() +
								field.slice(1);
							storeAddress[fnName](protoValue);
						}
					}
				);
				// dont save if country not set will always fail
				if (
					generalSettingsChanged.storeAddress.country != '' &&
					typeof generalSettingsChanged.storeAddress.country !=
						'undefined'
				) {
					generalSettings.setStoreAddress(storeAddress);
				}

				//store standard and formats
				const standardFormats = new StandardFormats();
				standardFormats.setTimezone(
					generalSettingsChanged.storeStandardFormats.timezone
				);

				let orderPrefixValue = new StringValue();
				orderPrefixValue.setValue(
					generalSettingsChanged.storeStandardFormats.orderPrefix
				);
				standardFormats.setOrderPrefix(orderPrefixValue);

				let orderSuffixValue = new StringValue();
				orderSuffixValue.setValue(
					generalSettingsChanged.storeStandardFormats.orderSuffix
				);
				standardFormats.setOrderSuffix(orderSuffixValue);
				generalSettings.setStoreStandardFormats(standardFormats);

				//store currency
				const storeCurrency = new StoreCurrency();
				storeCurrency.setCurrency(
					generalSettingsChanged.storeCurrency.currency
				);

				fieldsList = [
					'htmlWithCurrency',
					'htmlWithoutCurrency',
					'emailWithCurrency',
					'emailWithoutCurrency',
				];
				Object.keys(generalSettingsChanged.storeCurrency).forEach(
					(field) => {
						if (fieldsList.includes(field)) {
							let protoValue = new StringValue();
							protoValue.setValue(
								generalSettingsChanged.storeCurrency[field]
							);
							let fnName =
								'set' +
								field.charAt(0).toUpperCase() +
								field.slice(1);
							storeCurrency[fnName](protoValue);
						}
					}
				);
				generalSettings.setStoreCurrency(storeCurrency);

				settingsRequest.setGeneralSettings(generalSettings);

				grpc.invoke(Settings.SetStoreSettings, {
					request: settingsRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		getLegalSettings() {
			return new Promise((resolve, reject) => {
				let emptyRequest = new Empty();

				grpc.invoke(Settings.GetLegalSettings, {
					request: emptyRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		saveLegalSettingsChanges(legalSettingsChanged) {
			return new Promise((resolve, reject) => {
				const settingsRequest = new SettingsRequest();
				const legalSettings = new Legal();

				let refundPolicyValue = new StringValue();
				refundPolicyValue.setValue(
					legalSettingsChanged.refundPolicy.value
				);
				legalSettings.setRefundPolicy(refundPolicyValue);

				let privacyPolicyValue = new StringValue();
				privacyPolicyValue.setValue(
					legalSettingsChanged.privacyPolicy.value
				);
				legalSettings.setPrivacyPolicy(privacyPolicyValue);

				let termsOfServiceValue = new StringValue();
				termsOfServiceValue.setValue(
					legalSettingsChanged.termsOfService.value
				);
				legalSettings.setTermsOfService(termsOfServiceValue);

				let shippingPolicyValue = new StringValue();
				shippingPolicyValue.setValue(
					legalSettingsChanged.shippingPolicy.value
				);
				legalSettings.setShippingPolicy(shippingPolicyValue);

				settingsRequest.setLegalSettings(legalSettings);

				grpc.invoke(Settings.SetLegalSettings, {
					request: settingsRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		getCheckoutSettings() {
			return new Promise((resolve, reject) => {
				let emptyRequest = new Empty();

				grpc.invoke(Settings.GetCheckoutSettings, {
					request: emptyRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		saveCheckoutSettingsChanges(checkoutSettingsChanges) {
			return new Promise((resolve, reject) => {
				const settingsRequest = new SettingsRequest();
				const checkoutSettings = new Checkout();
				checkoutSettings.setCustomerAccounts(
					parseInt(checkoutSettingsChanges.customerAccounts)
				);
				checkoutSettings.setTipping(checkoutSettingsChanges.tipping);
				checkoutSettings.setEmailSubscription(
					checkoutSettingsChanges.emailSubscription
				);

				let formOptions = new FormOptions();
				let fields = [
					'fullNameField',
					'companyName',
					'addressLine2',
					'shippingAddressPhone',
				];
				fields.forEach((field) => {
					formOptions[
						'set' + field.charAt(0).toUpperCase() + field.slice(1)
					](checkoutSettingsChanges.formOptions[field]);
				});
				checkoutSettings.setFormOptions(formOptions);

				let orderProcessing = new OrderProcessing();
				fields = [
					'shippingAsBillingDefault',
					'addressCompletion',
					'autoFulfill',
					'autoArchive',
				];
				fields.forEach((field) => {
					orderProcessing[
						'set' + field.charAt(0).toUpperCase() + field.slice(1)
					](checkoutSettingsChanges.orderProcessing[field]);
				});
				checkoutSettings.setOrderProcessing(orderProcessing);

				let abandonedCheckout = new AbandonedCheckout();
				fields = ['automaticallySendEmails', 'sendTo', 'sendAfter'];
				fields.forEach((field) => {
					abandonedCheckout[
						'set' + field.charAt(0).toUpperCase() + field.slice(1)
					](checkoutSettingsChanges.abandonedCheckout[field]);
				});
				checkoutSettings.setAbandonedCheckout(abandonedCheckout);

				settingsRequest.setCheckoutSettings(checkoutSettings);

				grpc.invoke(Settings.SetCheckoutSettings, {
					request: settingsRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		saveAnalyticsSettings(analyticsData) {
			return new Promise((resolve, reject) => {
				const settingsRequest = new SettingsRequest();
				let analyticsSettings = new Analytics();
				let googleAnalytics = new Google();

				if (!isEmpty(analyticsData.websiteId))
					googleAnalytics.setWebsiteId(analyticsData.websiteId);

				if (!isEmpty(analyticsData.propertyId))
					googleAnalytics.setPropertyId(analyticsData.propertyId);

				if (!isEmpty(analyticsData.code))
					googleAnalytics.setCode(analyticsData.code);

				if (!isEmpty(analyticsData.state))
					googleAnalytics.setState(analyticsData.state);

				analyticsSettings.setGoogleAnalytics(googleAnalytics);
				settingsRequest.setAnalyticsSettings(analyticsSettings);

				grpc.invoke(Settings.SetAnalyticsSettings, {
					request: settingsRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		getAnalyticsSettings() {
			const clientsStore = useClientsStore();
			return new Promise((resolve, reject) => {
				const settingsRequest = new SettingsRequest();
				let analyticsSettings = new Analytics();
				let googleAnalytics = new Google();

				analyticsSettings.setGoogleAnalytics(googleAnalytics);
				settingsRequest.setAnalyticsSettings(analyticsSettings);

				grpc.invoke(Settings.GetAnalyticsSettings, {
					request: settingsRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': clientsStore.getSelectedStore,
						'x-store-url': clientsStore.getSelectedStoreUrl,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		getAnalyticsAccessToken(analyticsData) {
			const clientsStore = useClientsStore();
			return new Promise((resolve, reject) => {
				const settingsRequest = new SettingsRequest();
				let analyticsSettings = new Analytics();
				let googleAnalytics = new Google();

				if (!isEmpty(analyticsData.code))
					googleAnalytics.setCode(analyticsData.code);

				if (!isEmpty(analyticsData.state))
					googleAnalytics.setState(analyticsData.state);

				analyticsSettings.setGoogleAnalytics(googleAnalytics);
				settingsRequest.setAnalyticsSettings(analyticsSettings);

				grpc.invoke(Settings.GetAnalyticsAccessToken, {
					request: settingsRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': clientsStore.getSelectedStore,
						'x-store-url': clientsStore.getSelectedStoreUrl,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		getRedirects([pageNum, perPage = 50]) {
			return new Promise((resolve, reject) => {
				let redirectsRequest = new RedirectListing();
				if (pageNum) redirectsRequest.setPageNum(pageNum);
				if (perPage) redirectsRequest.setDisplayResult(perPage);

				grpc.invoke(Settings.GetRedirects, {
					request: redirectsRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		setRedirect(redirect) {
			return new Promise((resolve, reject) => {
				let redirectsRequest = new RedirectRequest();
				console.log(redirect);
				redirectsRequest.setActionType(redirect.actionType);
				redirectsRequest.setFromUrl(redirect.fromUrl);
				redirectsRequest.setToUrl(redirect.toUrl);
				redirectsRequest.setRedirectStatus(redirect.redirectStatus);
				redirectsRequest.setAllowPartialMatch(
					redirect.allowPartialMatch
				);

				grpc.invoke(Settings.SetRedirect, {
					request: redirectsRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		getBlocklist([pageNum, perPage = 50]) {
			return new Promise((resolve, reject) => {
				let blocklistRequest = new BlockedIPListing();
				if (pageNum) blocklistRequest.setPageNum(pageNum);
				if (perPage) blocklistRequest.setDisplayResult(perPage);

				grpc.invoke(Settings.GetBlockedIP, {
					request: blocklistRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		blockIp(ipObject) {
			return new Promise((resolve, reject) => {
				let blockRequest = new BlockedIPRequest();

				blockRequest.setIpAddress(ipObject.ipAddress);

				if (!isEmpty(ipObject.blockedReason)) {
					let reason = new StringValue();
					reason.setValue(ipObject.blockedReason);
					blockRequest.setBlockedReason(reason);
				}

				grpc.invoke(Settings.BlockIP, {
					request: blockRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		deleteBlockedIp(ipObject) {
			return new Promise((resolve, reject) => {
				let blockRequest = new BlockedIPRequest();
				blockRequest.setIpAddress(ipObject.ipAddress);

				grpc.invoke(Settings.DeleteBlockIP, {
					request: blockRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
	},
	getters: {},
});
